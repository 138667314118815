<template>
  <div id="header" class="container-fuild">
    <!-- 头部顶部 -->
    <!-- <div class="header-top container-fuild hidden-xs">
      <div class="container">
        <div class="server pull-left">
          <span class="glyphicon glyphicon-earphone"></span>888-888-888
          <span class="glyphicon glyphicon-envelope"></span>liyunkun_11@163.com
          <span class="glyphicon glyphicon-time"></span>7x24小时为您服务
        </div>
        <div class="shejiao pull-right">
          <span class="glyphicon glyphicon-hand-right"></span>赶快联系我们吧！
          <span class="glyphicon glyphicon-hand-left"></span>
        </div>
      </div>
    </div> -->
    <div class="header-nav container hidden-xs">
      <!-- 导航logo -->
      <div class="header-nav-logo" @click="logoClick">
        <img src="@/assets/logo/titlelogo2.png" />
      </div>
      <!-- 导航内容 -->
      <ul class="header-nav-wrapper">
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="index == navIndex ? 'active' : ''"
          @click="navClick(index, item.name)"
        >
          <router-link :to="item.path">
            {{ item.name }}
            <span
              v-if="item.children.length > 0"
              class="glyphicon glyphicon-menu-down"
            ></span>
            <i class="underline"></i>
          </router-link>
          <dl v-if="item.children.length > 0">
            <dt v-for="(i, n) in item.children" :key="n">
              <router-link :to="i.path">{{ i.name }}</router-link>
            </dt>
          </dl>
        </li>
      </ul>
    </div>
    <!-- 手机导航 -->
    <div class="header-nav-m container-fuild visible-xs">
      <div class="header-nav-m-logo">
        <img
          class="center-block"
          src="@/assets/logo/titlelogo2.png"
          alt="logo"
        />
      </div>
      <!-- 导航栏 -->
      <div class="header-nav-m-menu text-center">
        {{ menuName }}
        <div
          class="header-nav-m-menu-wrapper"
          data-toggle="collapse"
          data-target="#menu"
          @click="menuClick"
        >
          <span :class="menuClass"></span>
        </div>
        <!-- 导航内容 -->
        <ul id="menu" class="header-nav-m-wrapper collapse">
          <li
            v-for="(item, index) in navList"
            :key="index"
            :class="index == navIndex ? 'active' : ''"
            @click="navClick(index, item.name)"
            data-toggle="collapse"
            data-target="#menu"
          >
            <router-link :to="item.path">
              {{ item.name }}
              <i class="underline"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      navIndex: sessionStorage.getItem("navIndex") ? sessionStorage.getItem("navIndex") : 0,
      menuName: "首页",
      menuClass: "glyphicon glyphicon-menu-down",
      navList: [
        {
          name: "首页",
          path: "/",
          children: [],
        },
        {
          name: "产品服务",
          path: "/service",
          children: [],
        },
        {
          name: "行业案例",
          path: "/industry",
          children: [],
        },
        {
          name: "新闻动态",
          path: "/news",
          children: [],
        },
        {
          name: "公司介绍",
          path: "/introduction",
          children: [],
        },
        {
          name: "加入我们",
          path: "/joinus",
          children: [],
        },
        {
          name: "联系我们",
          path: "/contactus",
          children: [],
        },
      ],
      swiperList: [
        {
          img: require("@/assets/img/banner1.png"),
          path: "",
          title: "您身边的IT专家1",
          content:
            "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
        {
          img: require("@/assets/img/banner2.jpg"),
          path: "",
          title: "您身边的IT专家2",
          content:
            "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
        {
          img: require("@/assets/img/banner1.png"),
          path: "",
          title: "您身边的IT专家3",
          content:
            "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
        {
          img: require("@/assets/img/banner2.jpg"),
          path: "",
          title: "您身边的IT专家4",
          content:
            "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
      ],
    };
  },
  methods: {
    logoClick() {
      this.$router.replace({
        path: '/home'
      })
      this.navIndex = 0
      sessionStorage.setItem("navIndex", this.navIndex)
    },
    navClick(index, name) {
      this.navIndex = index;
      sessionStorage.setItem("navIndex", index)
      this.menuName = name;
    },
    menuClick() {
      if (this.menuClass == "glyphicon glyphicon-menu-down") {
        this.menuClass = "glyphicon glyphicon-menu-up";
      } else {
        this.menuClass = "glyphicon glyphicon-menu-down";
      }
    },
  },
  mounted() {
    /* banner-swiper */
    // new Swiper(".banner-swiper", {
    //   loop: true, // 循环模式选项
    //   effect: "fade",
    //   //自动播放
    //   autoplay: {
    //     delay: 3000,
    //     stopOnLastSlide: false,
    //     disableOnInteraction: false,
    //   },
    //   // 如果需要分页器
    //   pagination: {
    //     el: ".swiper-pagination",
    //     clickable: true,
    //   },
    //   // 如果需要前进后退按钮
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    //   // 延迟加载
    //   lazy: {
    //     loadPrevNext: true,
    //   },
    //   observer: true, //修改swiper自己或子元素时，自动初始化swiper
    //   observeParents: true, //修改swiper的父元素时，自动初始化swiper
    // });
    /* customer-swiper */
    // new Swiper(".customer-swiper", {
    //   loop: true, // 循环模式选项
    //   slidesPerView: 3,
    //   //自动播放
    //   autoplay: {
    //     delay: 3000,
    //     stopOnLastSlide: false,
    //     disableOnInteraction: false,
    //   },
    //   // 如果需要前进后退按钮
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    //   observer: true, //修改swiper自己或子元素时，自动初始化swiper
    //   observeParents: true, //修改swiper的父元素时，自动初始化swiper
    // });
    /* wowjs动画 */
    // var wow = new WOW({
    //   boxClass: "wow",
    //   animateClass: "animated",
    //   offset: 0,
    //   mobile: true,
    //   live: true,
    // });
    // wow.init();
    //-------
    // var navbar = document.getElementById("titleBox");
    // var position = document.getElementById("position");
    // var navbarTop = navbar.offsetTop; // 获取导航栏到父元素的顶部距离
    // // console.log(navbarTop);
    // window.onscroll = function () {
    //   // 获取滚动的距离
    //   var scrollTop =
    //     document.documentElement.scrollTop ||
    //     window.pageYOffset ||
    //     document.body.scrollTop;
    //   /*方式一：动态绑定类*/
    //   // 滚动高度>元素距离顶部的位置时添加类，否则移除类
    //   scrollTop > navbarTop
    //     ? navbar.classList.add("fixed")
    //     : navbar.classList.remove("fixed");
    //   // 控制占位内容是否显示
    //   scrollTop > navbarTop
    //     ? position.classList.remove("mark")
    //     : position.classList.add("mark");
    // };
  },
};
</script>
<style scoped>
/* 顶部 */
#header {
  user-select: none;
  background: #f4f4f4;
  transition: all ease 0.6s;
}
#header .header-top {
  height: 50px;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  background: #474747;
}
/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}
/* 导航栏 */
#header .header-nav {
  height: 110px;
}
/* 导航栏logo */
#header .header-nav .header-nav-logo {
  width: 233px;
  height: 100%;
  float: left;
  position: relative;
  cursor: pointer;
}
/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  width: 233px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}
#header .header-nav .header-nav-wrapper {
  line-height: 110px;
  float: right;
  margin: 0;
  max-width: 800px;
}
/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper > li {
  float: left;
  margin: 0 15px;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper > li > a {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper > li > a > i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #1e73be;
}
/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper > li > a > span {
  font-size: 12px;
  transition: transform ease 0.5s;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover {
  color: #1e73be;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover span {
  transform: rotate(180deg);
}
/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper > li.active > a {
  color: #1e73be;
  text-decoration: none;
  border-bottom: 2px solid #1e73be;
}
/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper > li > dl {
  display: none;
  position: absolute;
  width: 168px;
  top: 80%;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 3px 1px #ccc;
  background: #fff;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper > li > dl > dt {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper > li > dl > dt > a:hover {
  text-decoration: none;
}
/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper > li:hover dl {
  display: block;
}
#header .header-nav .header-nav-wrapper > li > dl > dt:hover {
  cursor: pointer;
  background: #ccc;
}
@media screen and (max-width: 997px) {
  #header .header-nav-m {
    position: relative;
  }
  /* 导航栏logo容器 */
  #header .header-nav-m .header-nav-m-logo {
    height: 60px;
    position: relative;
  }
  /* 导航栏logo图片 */
  #header .header-nav-m .header-nav-m-logo img {
    /* width: 95px; */
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  /* 导航栏  菜单容器 */
  #header .header-nav-m .header-nav-m-menu {
    color: #fff;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    background: #474747;
    position: relative;
  }
  /* 导航栏 菜单图标 */
  #header .header-nav-m .header-nav-m-menu-wrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    width: 50px;
    height: 40px;
    color: #fff;
    z-index: 999999;
    font-size: 12px;
  }
  /* 导航栏 */
  #header .header-nav-m .header-nav-m-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background: #474747;
    z-index: 9999999;
  }
  /* 导航栏 每个导航 */
  #header .header-nav-m .header-nav-m-wrapper > li {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
  }
  /* 导航栏 每个导航下面的 a 链接 */
  #header .header-nav-m .header-nav-m-wrapper > li > a {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 0;
    position: relative;
  }
  /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
  #header .header-nav .header-nav-wrapper > li > a > span {
    font-size: 10px;
  }
}
</style>