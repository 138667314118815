<template>
  <div id="footer" class="container-fluid">
    <div class="logo">
      <img
        class="img-logo"
        src="@/assets/logo/deepbackgroundLOGO.png"
        alt="logo图"
      />
      <div>
        <img src="@/assets/logo/yc-qrcode.png" alt="logo图" />
        <p>之科云创公众号</p>
      </div>
    </div>
    <p class="address_tel_fax">
      <span>地址：杭州市余杭区文一西路1818号人工智能小镇10幢9楼</span>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span>电话：0571-56393328</span>
    </p>
    <p class="email_wx">
      <span>邮箱：zkyc@zhejianglab.com</span>
    </p>
    <p class="copy"><a target="_blank" rel="nofollow" href="http://beian.miit.gov.cn" style="color: #d3d3d3; text-decoration: none;">浙ICP备2022017290号</a> Copyright &copy; 2021 - 2022 浙江之科云创数字科技有限公司</p>
    <div style="width: 300px; margin: 0 auto; padding: 20px 0">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016490"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
        ><img src="@/assets/webSiteInfo/beian.jpg" style="float: left" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
        >
          浙公网安备 33011002016490号
        </p></a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>
<style scoped>
#footer {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #474747;
  overflow: hidden;
  text-align: center;
}
.logo .img-logo {
  margin-top: 10px;
}
.logo {
  margin: 30px auto;
  margin-bottom: 10px;
  width: 260px;
  display: flex;
  justify-content: space-between;
  /* background-color: rgb(194, 24, 24); */
  padding: 0;
  /* padding-top: 15px; */
}
.logo img {
  height: 93px;
}
.logo div p {
  width: 84px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 29px;
}
.address_tel_fax {
  text-align: center;
  color: #d3d3d3;
  font-size: 14px;
  margin: 10px 0;
}
.email_wx {
  color: #d3d3d3;
  font-size: 14px;
}
.copy {
  color: #d3d3d3;
  font-size: 14px;
  margin: 20px 0 10px;
}
@media screen and (max-width: 997px) {
  .title {
    font-size: 20px;
  }
  .address_tel_fax {
    font-size: 12px;
  }
  .email_wx {
    font-size: 12px;
  }
  .copy {
    font-size: 12px;
    margin: 30px 0 10px;
  }
}
</style>

