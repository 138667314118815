import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '/'
  }, 
  {
    path: '/',
    redirect: '/home'
  }, 
  {
    path: '/home',
    component: () => import('@/views/home'),
    meta: { title: '首页' }
  }, 
  {
    path: '/service',
    component: () => import('@/views/service'),
    meta: { title: '产品服务' }
  },
  {
    path: '/industry',
    component: () => import('@/views/industry'),
    meta: { title: '行业案例' }
  },
  {
    path: '/industry/:id',
    component: () => import('@/views/industry/detail'),
    meta: { title: '行业案例' }
  },
  {
    path: '/case/:id',
    name: 'caseDetail',
    component: () => import('@/views/case/detail.vue'),
    meta: { title: '案例详情' }
  },
  {
    path: '/news',
    component: () => import('@/views/news'),
    meta: { title: '新闻动态' }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/views/news/detail.vue'),
    meta: { title: '新闻详情' }
  },
  {
    path: '/introduction',
    component: () => import('@/views/introduction'),
    meta: { title: '公司介绍' }
  }, 
  {
    path: '/joinus',
    component: () => import('@/views/join'),
    meta: { title: '加入我们' }
  },
  {
    path: '/jobDetail',
    name: 'jobDetail',
    component: () => import('@/views/join/detail.vue'),
    meta: { title: '招聘详情' }
  },
  {
    path: '/contactus',
    component: () => import('@/views/contact'),
    meta: { title: '联系我们' }
  }
]

const router = new Router({
  routes
})

export default router